import React from 'react';
import Backgroundimage from '../../assets/images/background/image-4.jpg';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/logo.png'

function Main() {
    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Fenice"><img src={logonew} alt="" title="Fenice" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Caracas - Venezuela</li>
                                                <li><Link to="fenice@fenicelatam.com">fenice@fenicelatam.com</Link></li>
                                                <li>Mon to Sat 08:30 am - 04:30 pm</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                        <Link to="https://api.whatsapp.com/send?phone=584125864680">WhatsApp</Link>
                                            <div className="text">Our team is ready to help you</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/ourproducts">Products</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="https://www.instagram.com/feniceve">instagram</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2024 Feniceve. All Rights Reserved.<br></br>  </div>
                    </div>
                        <div className="footer-col links-col">
                            <ul className="links">
                                <li> <Link to="https://estebansabo.store" target="blank">Developer</Link></li>
                            </ul>
                        </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main