import React from "react";
import twoBg from "../../assets/images/background/a1.webp";
import threebg from "../../assets/images/background/a2.webp";
import { Link } from "react-router-dom";

function Winningchef() {
  return (
    <>
      <section className="fluid-section alternate">
        <div className="outer-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image-layer" style={{ backgroundImage: `url(${threebg})` }}></div>
                <div className="image">
                  <img src={threebg} alt="" />
                </div>
              </div>
            </div>

            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="content-box">
                  <div className="title-box centered">
                    <div className="subtitle">
                      <span>Our history</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h2>About us</h2>
                    <div className="text">
                      We are a company committed to providing practical and functional solutions for our clients. We are focused on offering
                      innovative products that adapt to the needs of the market. We offer versatile beverage consumption through high-quality plastic
                      cups and sealing equipment.
                    </div>
                  </div>
                  <Link to="/about" className="theme-btn btn-style-two clearfix">
                    <span className="btn-wrap">
                      <span className="text-one">Read More</span>
                      <span className="text-two">Read More</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fluid-section">
        <div className="outer-container">
          <div className="row clearfix">
            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image-layer" style={{ backgroundImage: `url(${twoBg})` }}></div>
                <div className="image">
                  <img src={twoBg} alt="" />
                </div>
              </div>
            </div>

            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="content-box">
                  <div className="title-box centered">
                    <div className="subtitle">
                      <span>We are Innovation!</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h2>Design and Utility</h2>
                    <div className="text">
                      At Fenice we are committed to providing our customers with an optimal and quality experience. We offer personalized customer
                      support, a wide variety of products to choose from, and a team of experts who are passionate about helping customers. Be amazed
                      by our innovative design, which has been praised by our customers. Contact us today to learn more!
                    </div>
                  </div>
                  <Link to="/ourproducts" className="theme-btn btn-style-two clearfix">
                    <span className="btn-wrap">
                      <span className="text-one">Our products</span>
                      <span className="text-two">Our products</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Winningchef;
