import React from 'react'
import Chefsection from '../Menchefs/Chefsection'
import Startermenu from '../Allmenus/FirstMenu/Startermenu'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-5.webp'

function Main() {
  return (

    <>
      <Bredcrumb subtitle="Meet Our Products" title="OUR PRODUCTS" Img={Img} />
      <Startermenu />
      <Chefsection />
    </>

  )
}

export default Main