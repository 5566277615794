import React from 'react'
import { Link } from 'react-router-dom'
import One from '../../assets/images/resource/featured-1.jpg'
import Two from '../../assets/images/resource/featured-2.webp'
import Three from '../../assets/images/resource/featured-3.jpg'
import Sone from '../../assets/images/resource/featured-1.jpg'
import Stwo from '../../assets/images/resource/featured-2.webp'
import Sthree from '../../assets/images/resource/featured-3.jpg'

function Feature() {
    return (
        <>
            <section className="featured-links">
                <div className="outer-container">
                    <div className="row clearfix">
                        <div className="link-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="text-block">
                                    <div className="bl-inner">
                                        <div className="content">
                                            <div className="subtitle">Quality Control</div>
                                            <h3>Our guarantee is quality</h3>
                                            <div className="text">
                                            We have the necessary processes to guarantee the optimal performance of our equipment.
                                            </div>
                                            <div className="link">
                                                <Link to="" className="theme-btn">
                                                    
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-box">
                                    <div className="image-layer" style={{ backgroundImage: `url(${One})` }} />
                                    <div className="image">
                                        <img src={Sone} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="link-block alternate col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="image-box">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Two})` }} />
                                    <div className="image">
                                        <img src={Stwo} alt="" />
                                    </div>
                                </div>
                                <div className="text-block">
                                    <div className="bl-inner">
                                        <div className="content">
                                            <div className="subtitle"></div>
                                            
                                            <h2>Follow Us</h2>
                                            <div></div>
                                            <div className="link">
                                                <Link to="https://www.instagram.com/feniceve" className="theme-btn">
                                                    <div className="subtitle"></div>
                                                    <h3>Instagram</h3>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="link-block col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div className="inner wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms" >
                                <div className="text-block">
                                    <div className="bl-inner">
                                        <div className="content">
                                            <div className="subtitle">Designs for you</div>
                                            <h3>Make it yours</h3>
                                            <br>
                                            </br>
                                            <div className="text">We adapt our products to your brand. Giving you unparalleled personalization, so that you stand out from the rest.</div>
                                            <br>
                                            </br>
                                            <div className="link">
                                                <Link to="" className="theme-btn">
                                                    <span></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-box">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Three})` }} />
                                    <div className="image">
                                        <img src={Sthree} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Feature