import React from 'react'
import { Link } from 'react-router-dom'
import teamone from '../../assets/images/resource/team-1.jpg'
import teamtwo from '../../assets/images/resource/team-2.jpg'
import teamthree from '../../assets/images/resource/team-3.jpg'

function Teem() {
    return (
        <>
            <section className="team-section">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Clients</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>
                        <h2>Meet some of our Clients</h2>
                        <br></br>
                        <h4>We are present in multiple businesses</h4>
                    </div>
                    <div className="row justify-content-center clearfix">

                        <div className="team-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image">
                                    <img src={teamone} alt="" />
                                    <div className="overlay-box">
                                        <div className="overlay-inner">

                                            <ul className="social-box">
                                                <li><Link to="https://www.instagram.com/azupasteleria" className="fab fa-instagram"></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h3><Link to="#">Azu Pasteleria</Link></h3>
                                <div className="designation">Shops</div>
                                <div className="text desc">We have a different range of products that adapt to your business.</div>
                            </div>
                        </div>


                        <div className="team-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="image">
                                    <img src={teamtwo} alt="" />
                                    <div className="overlay-box">
                                        <div className="overlay-inner">
                                            <ul className="social-box">
                                                <li><Link to="https://www.instagram.com/lidotel" className="fab fa-instagram"></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h3><Link to="#">Lidotel</Link></h3>
                                <div className="designation">Hotels</div>
                                <div className="text desc">Our machines are designed with the best quality to serve any customer flow.</div>
                            </div>
                        </div>

                        <div className="team-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="image">
                                    <img src={teamthree} alt="" />
                                    <div className="overlay-box">
                                        <div className="overlay-inner">
                                            <ul className="social-box">
                                                <li><Link to="https://www.instagram.com/migascafe_ve" className="fab fa-instagram"></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h3><Link to="#">Miga's Café</Link></h3>
                                <div className="designation">Restaurants</div>
                                <div className="text desc">No matter the size of your business, we have the equipment that adapts to you.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Teem