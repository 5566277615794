import React from "react";
import { Link } from "react-router-dom";
import One from "../../../assets/images/products/menu-image-11.webp";
import Two from "../../../assets/images/products/menu-image-12.webp";
import Seven from "../../../assets/images/products/menu-image-17.webp";
import Four from "../../../assets/images/products/menu-image-14.webp";
import Five from "../../../assets/images/products/menu-image-15.webp";
import Six from "../../../assets/images/products/menu-image-16.webp";
import Eight from "../../../assets/images/products/menu-image-18.webp";
import catalog from "../../../assets/catalog/FeniceCatalog.pdf";

function Specialmenu() {
  return (
    <>
      <section className="special-offer-two">
        <div className="auto-container">
          <div className="title-box centered">
            <br></br>
            <br></br>
            <h2>Plastic Cans</h2>
          </div>
          <div className="row clearfix">
            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image">
                  <img src={Eight} alt="" />
                </div>
                <h4>
                  <Link to="/menu">250 ml</Link>
                </h4>
                <div className="text desc">Small, compact and practical for every application.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image">
                  <img src={One} alt="" />
                </div>
                <h4>
                  <Link to="/menu">330 ml</Link>
                </h4>
                <div className="text desc">Small, the perfect size for cocktails, juices, iced coffees and more.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Two} alt="" />
                </div>
                <h4>
                  <Link to="/menu">500 ml</Link>
                </h4>
                <div className="text desc">Large size, our glasses are suitable for any type of drink. Highlight your brand.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Seven} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Accessories</Link>
                </h4>
                <div className="text desc">Ring-Pull and Lids</div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-link-box text-center">
          <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
            <span className="btn-wrap">
              <span className="text-one">Download our 2024 Catalog</span>
              <span className="text-two">Download our 2024 Catalog</span>
            </span>
          </a>
        </div>
      </section>

      <section className="special-offer-two">
        <div className="auto-container">
          <div className="title-box centered">
            <h2>Our Machines</h2>
          </div>
          <div className="row clearfix">
            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Four} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Automatic</Link>
                </h4>
                <div className="text desc">Ideal for any medium or large requirement.</div>
              </div>
            </div>

            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                <div className="image">
                  <img src={Five} alt="" />
                </div>
                <h4>
                  <Link to="/menu">Full-Automatic</Link>
                </h4>
                <div className="text desc">Our flagship machine. Taken to another level.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-link-box text-center">
          <a href={catalog} download="FENICECATALOG" className="theme-btn btn-style-two clearfix">
            <span className="btn-wrap">
              <span className="text-one">Download our 2024 Catalog</span>
              <span className="text-two">Download our 2024 Catalog</span>
            </span>
          </a>
        </div>
      </section>

      <section className="chef-selection">
        <div className="auto-container">
          <div className="outer-container">
            <div className="row clearfix">
              <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                  <div className="image-layer" style={{ backgroundImage: `url(${Six})` }}></div>
                </div>
              </div>

              <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                  <div className="title-box">
                    <div className="subtitle">
                      <span>Let's work together</span>
                    </div>

                    <div className="pattern-image">
                      <img src={require("../../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                    </div>

                    <h3>We are Fenice!</h3>
                    <div className="text">
                      Let yourself be carried away by a unique experience, our glasses sealed by our machines stand out in an unparalleled way and are
                      a unique and innovative attraction at any event or location.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Specialmenu;
