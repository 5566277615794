import React from 'react'
import Videosection from '../Menhome/Videosection'
import Special from '../Menhome/Special'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Whyus from '../Menhome/Whyus'
import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'
import Sliderclients from '../Menhome/Sliderclients'
import Aboutfront from '../Menhome/Aboutfront'





function Main() {
    return (
        <>
            <div className="menu-backdrop"> </div>

            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    <div className="logo-box"><Link to="/" title="Fenice"><img src={sidebarlogo} alt="" title="Italian Design" /></Link></div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className="current dropdown"><Link to="/">Home</Link></li>
                            <li><Link to="/ourproducts">Our Products</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <h2>Contact Us</h2>
                    <ul className="info">
                        <li>Caracas<br /> Venezuela</li>
                        <li>Mon to Sat : 08.30 am - 04.30 pm</li>
                        <li><Link to="mailto:fenice@fenicelatam.com">fenice@fenicelatam.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Phone Number</div>
                        <div className="bk-no"><Link to="https://api.whatsapp.com/send?phone=584125864680">WhatsApp</Link></div>
                    </div>
                </div>
            </section>

            <Banner />
            <Special />
            <Sliderclients />
            <Aboutfront />
            <Offer />
            <Videosection />
            <Whyus />
        </>
    )
}

export default Main