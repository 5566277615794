import React from 'react'
import bg26 from '../../assets/images/background/bg-26.png'
import mainchef from '../../assets/images/resource/mainchef.webp'

function Chefsection() {
    return (
        <>
            <section className="chef-section">
                <div className="right-bg"><img src={bg26} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><img src={mainchef} alt="" /></div>
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box">
                                        <div className="subtitle"><span>We are unique</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>
                                        <h2>Award Winning Quality</h2>
                                        <div className="text">If you are looking for exceptional quality and cutting-edge Italian design in beverage packaging, then we are the company for you. Contact us today to learn more about our products and services, we are ready yo help you.</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chefsection